import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Categories`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`EVENTS > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caller ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Classification(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Classification(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Center number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(IVRsView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Summary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact hangup origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Customer identification(BroadcastContactsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(CallAttemptsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Customer identification(BroadcastContactsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(CallAttemptsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Location information(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(EventsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ECOMMERCE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation reason`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SEND INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of initial scheduling`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(VisitorsView)`}</li><li>{`SESSION INFORMATION > Device information(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > Device information(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > Device information(ContactsView)`}</li><li>{`SESSION INFORMATION > Device information(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnection origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(CallAttemptsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External Campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(EventsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FID (external ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`From`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause (contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has conversion?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Have on-demand recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(IVRsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOMER INFORMATION > Customer identification(BroadcastContactsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(CallAttemptsView)`}</li><li>{`CUSTOMER INFORMATION > Customer identification(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of 1st attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Location information(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`CONTACT INFORMATION > Location information(LeadManagementView)`}</li><li>{`EVENTS > > Without sub-category(EventsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is it classified?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is short call?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SEND INFO > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SEND INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LEAD INFORMATION > > Without sub-category(ContactsView)`}</li><li>{`LEAD INFORMATION > > Without sub-category(LeadManagementView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`DEVICE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`DEVICE INFORMATION > > Without sub-category(EventsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(ContactCenterActivityView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Operator(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Operator(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator identification method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`CONTACT INFORMATION > General(LeadManagementView)`}</li><li>{`EVENTS > > Without sub-category(EventsView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Origin contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`CONTACT INFORMATION > General(LeadManagementView)`}</li><li>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording On-demand`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`EVENTS > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Location information(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Location information(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Location information(ContactsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(EventsView)`}</li><li>{`LOCATION INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`VISITORS INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reply to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`EVENTS > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(BroadcastContactsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(CallAttemptsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(BroadcastContactsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(CallAttemptsView)`}</li><li>{`CUSTOMER INFORMATION > Segments(ContactsView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(VisitorsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SEND INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sentiment Analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li><li>{`CONTACT INFORMATION > > Without sub-category(IVRsView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li><li>{`OUTBOUND CAMPAIGNS > > Without sub-category(OutboundCampaignActionsByActionDateView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Source`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`ECOMMERCE INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushActionsView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsPushView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`SEND INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status per contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sub status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ANOUNYMOUS CATEGORY > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CAMPAIGN INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Other services(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Other services(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Other services(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(EventsView)`}</li><li>{`SESSION INFORMATION > General(BroadcastContactsView)`}</li><li>{`SESSION INFORMATION > General(CallAttemptsView)`}</li><li>{`SESSION INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsubscribe date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CONTACT INFO > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`VISITORS INFORMATION > > Without sub-category(EcommerceView)`}</li><li>{`VISITORS INFORMATION > > Without sub-category(EventsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice actions sequence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Specific by contact type(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Specific by contact type(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > > Without sub-category(ContactCenterActivityView)`}</li><li>{`CONTACT INFORMATION > General(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > General(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > General(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CAMPAIGN INFORMATION > > Without sub-category(BroadcastContactsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(CallAttemptsView)`}</li><li>{`CAMPAIGN INFORMATION > > Without sub-category(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Contact finalization(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Contact finalization(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CART CREATION DATE > > Without sub-category(EcommerceView)`}</li><li>{`DATE > > Without sub-category(ContactCenterActivityView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CONTACT INFORMATION > Dates and times(BroadcastContactsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(CallAttemptsView)`}</li><li>{`CONTACT INFORMATION > Dates and times(ContactsView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_35`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_41`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_44`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_46`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_47`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsEmailView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsSMSView)`}</li><li>{`CUSTOM PARAMETERS > > Without sub-category(OutboundCampaignsVoiceView)`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      